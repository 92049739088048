import React from 'react';

function NavBar(props) {
    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <a className="navbar-brand" href="/">
                Home
            </a>
            <a className="navbar-brand" href="/">
                Link 1
            </a>
            <a className="navbar-brand" href="/">
                Link 2
            </a>
        </nav>
    );
}

export default NavBar;